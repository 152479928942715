<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        emissions:[],
        }
    }
  }
</script>
