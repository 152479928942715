import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '/dashboard',
          component: () => import('@/views/dashboard/Index'),
        },
        {
          name: 'LUCEE',
          path: '',
          component: () => import('@/views/LUCEE'),
        },
        {
          name: 'Chantier',
          path: '/chantier/:code_chantier',
          component: () => import('@/views/Chantier'),
        }
      ],
    },
  ],
})
